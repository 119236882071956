<template>
  <el-dialog  :title="title"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="500px" @open="openModal"
              @closed="closeModal">
    <ch-form
        :render-option="option"
        :model="modalForm"
        ref="form"
        :rules="rules"
        :props="{ labelWidth: '80px', paddingRight: '0px' }"
    ></ch-form>
    <div slot="footer" class="flex_con">
      <ch-button type="ok" @click="handleModal" :loading="loading">确定</ch-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  props:{
    getListFunction:{
      type: Function
    }
  },
  data(){
    return {
      isOpenModal:false,
      loading:false,
      title: '',
      type: '',
      modalForm: {},
      rules:{
        name: [{ required: true, message: '请输入名称', trigger: ['blur', 'change'] }]
      }
    }
  },
  computed: {
    option() {
      return [
        {type: 'input', label: '名称', prop: 'name', placeholder: '请输入名称',props:{showWordLimit:true,maxlength:6}},
      ]
    }
  },
  methods:{
    openModal() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
    },
    openAdd(){
      this.isOpenModal = true
      this.title = '新增标签'
      this.type = 'add'
    },
    openEdit(row){
      this.isOpenModal = true
      this.modalForm = JSON.parse(JSON.stringify(row));
      this.title = '编辑标签'
      this.type = 'edit'
    },
    handleModal() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.isSubmitLoading = true
          if(this.type === 'add'){
            this.$curl.post('/hm/hmGoodsServeTagLibrary/add', this.modalForm).then(() => {
              this.$message.success('新增成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }else{
            this.$curl.put('/hm/hmGoodsServeTagLibrary/edit', this.modalForm).then(() => {
              this.$message.success('编辑成功')
              this.closeModal()
              this.getListFunction()
            }).finally(() => {
              this.isSubmitLoading = false
            })
          }

        }
      })
    },
    closeModal(){
      this.isOpenModal = false
      this.modalForm = {}
    }
  }
}
</script>
<style scoped lang="scss">

</style>